<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>商品配置</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">配置类型：</div>
                <el-select v-model="search.configType" placeholder="请选择">
                  <el-option :label="item.label" :value="item.value"
                             v-for="(item, index) in configTypeList" :key="index"></el-option>
                </el-select>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="configurationInfoAdd" @click="goAdd">新增商品配置</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" label="配置类型">
              <template slot-scope="scope">{{scope.row.configType | getConfigType}}</template>
            </el-table-column>
            <el-table-column align="center" prop="configContent" label="配置内容" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
            <el-table-column align="center" prop="createDate" label="创建时间"></el-table-column>
            <el-table-column align="center" prop="updateUserName" label="最后修改人"></el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="configurationInfoUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="configurationInfoDel" @click="del(scope.row.id)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :title="type === 'details' ? '详情' : type === 'edit' ? '修改' : '新增'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
    >
      <el-form v-if="upData && formDataReady" :model="upData" :rules="rules" ref="ruleForm" :label-position="labelPosition" label-width="100px">
        <el-form-item label="配置类型：" prop="configType">
          <span v-if="type === 'details'">{{upData.configType | getConfigType}}</span>
          <el-select v-else v-model="upData.configType" placeholder="请选择配置类型">
            <el-option :label="item.label" :value="item.value" v-for="(item, index) in configTypeList"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <span v-if="type === 'details'">{{upData.sort}}</span>
          <el-input-number v-else type="number" :step-strictly="true" :controls="false" :step="1" v-model="upData.sort" placeholder="请输入排序"></el-input-number>
        </el-form-item>
        <el-form-item label="配置内容：" prop="configContent" style="width: 100%;">
          <span v-if="type === 'details'">{{upData.configContent}}</span>
          <el-input
              v-else
              type="textarea"
              placeholder="请输入配置内容"
              v-model="upData.configContent"
              :autosize="{ minRows: 6, maxRows: 8}"
              maxlength="30"
              show-word-limit
          />
        </el-form-item>
      </el-form>
      <span v-if="type === 'details'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="add" v-if="type !== 'details'">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogImgVisible">
      <img width="100%" :src="dialogImgUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
let _this = this;
export default {
  data() {
    const validateSort = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入排序'));
      }else {
        callback();
      }
    };
    _this = this;
    return {
      labelPosition: 'top',
      search: {
        configType: null
      },
      type: '',
      tableList: [],
      upList: [],
      configTypeList: [{value: 1, label: '物流'},{value: 2, label: '服务'}],
      dialogVisible: false,
      dialogImgVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {
        configContent: "",
        configType: 1,
        sort: 0,
      },
      rules: {
        configContent: [{required: true, message: "请输入配置内容", trigger: "blur"}],
        configType: [{required: true, message: "请选择配置类型", trigger: "change"}],
        sort: [{required: true, validator: validateSort, trigger: "blur"}],
      },
      configurationInfoAdd: false,
      configurationInfoDel: false,
      configurationInfoUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  filters: {
    getConfigType(value) {
      const data = _this.configTypeList.filter(item => item.value == value);
      // 确保有值
      if (data && data.length > 0) {
        return data[0].label;
      }
    }
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getConfigurationInfo(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));
      list.forEach((v) => {
        if (v == "configurationInfo:add") {
          _this.configurationInfoAdd = true;
        }
        if (v == "configurationInfo:del") {
          _this.configurationInfoDel = true;
        }
        if (v == "configurationInfo:upd") {
          _this.configurationInfoUpd = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    add() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (!_this.upData.id) {
            _this.$api.addConfigurationInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.btnLoading = false;
                _this.$message.success("添加成功");
                _this.handleClose();
                _this.getData();
              }
            });
          } else {
            _this.$api.editConfigurationInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.btnLoading = false;
                _this.$message.success("修改成功");
                _this.getData();
                _this.handleClose();
              }
            });
          }
        } else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        configContent: "",
        configType: 1,
        sort: 0,
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        configType: null
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },

    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = "add";
      _this.labelPosition = "top";
      _this.upData = {
        configContent: "",
        configType: 1,
        sort: 0,
      }
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = "details";
      _this.labelPosition = "right";
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = "edit";
      _this.labelPosition = "top";
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delConfigurationInfo({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}
.el-input-number {
  width: 100%;
  /deep/.el-input__inner {
    text-align: left;
  }
}
</style>
